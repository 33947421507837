<template>
  <div class="categories">
    <h1>Sélectionnez la catégorie de votre choix</h1>
    <div class="card-container">
      <div class="card" v-for="c in categories" :key="c.id">
        <router-link :to="{ name: 'Categorie', params: {
            slug: c.strCategory,
            id: c.idCategory,
            descri: c.strCategoryDescription
          }
        }">
          <img :src="c.strCategoryThumb" >
          <h3>{{ c.strCategory }}</h3>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Categories',
  data () {
    return {
      categories: null
    }
  },
  mounted () {
    fetch('https://www.themealdb.com/api/json/v1/1/categories.php')
      .then(response => response.json())
      .then(response => {
        this.categories = response.categories
      })
  }
}
</script>

<style scoped>
  .card-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
  .card {
    padding: 15px;
    transition: transform .3s ease-out;
  }
  .card:hover {
    transform: translate(0, -10px);
  }
</style>
