<template>
  <div v-if="meal" class="meal">
    <div class="product-container">
      <div class="img-holder">
        <img class="thumb" :src="meal.strMealThumb">
      </div>
      <div class="recipe-holder">
        <h1>Recette : {{ meal.strMeal }}</h1>
        <h3>Catégorie : <router-link :to="{ name: 'Categorie', params: {
           slug: meal.strCategory
          }
        }" >{{ meal.strCategory }}</router-link></h3>
        <h3>Zone géographique : <router-link :to="{ name: 'Zone', params: {
           slug: meal.strArea
          }
        }" >{{ meal.strArea }}</router-link></h3>
        <h2>Ingrédients : </h2>
        <ul>
          <li v-for="(ing, index) in ingredients" :key="index">
            <strong>{{ ing.ing }} : </strong>{{ ing.q }}
          </li>
        </ul>
      </div>
    </div>
    <div class="instructions">
      <h2>Instructions : </h2>
      <p>{{ meal.strInstructions }}</p>
    </div>
    <div class="tags">
      <h2>Voir d'autres recettes avec les ingrédients :</h2>
      <span v-for="(ing, index) in ingredients" :key="index" class="btn-secondary">
        <router-link :to="{ name: 'Ingredients', params: {
            slug: ing.slug,
            name: ing.ing
          }
        }">
          {{ ing.ing }}
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Meal',
  props: {
    id: {
      type: String
    }
  },
  data () {
    return {
      meal: null,
      ingredients: []
    }
  },
  mounted () {
    fetch('https://www.themealdb.com/api/json/v1/1/lookup.php?i=' + this.id)
      .then(response => response.json())
      .then(response => {
        if (response.meals[0] != null) {
          this.meal = response.meals[0]
          console.log(this.meal)
          this.setIngredients()
        } else throw new Error('Bad Meal')
      })
      .catch(e => {
        this.$router.push({ path: '/404' })
      })
  },
  methods: {
    setIngredients () {
      for (let i = 1; i <= 20; i++) {
        if (this.meal[`strIngredient${i}`] !== '') {
          const slug = this.meal[`strIngredient${i}`].replaceAll(' ', '_').toLowerCase()
          this.ingredients.push({ ing: `${this.meal[`strIngredient${i}`]}`, q: `${this.meal[`strMeasure${i}`]}`, slug: slug })
        } else break
      }
    }
  }
}
</script>

<style scoped>
.product-container {
  padding-top: 50px;
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: auto;
}
@media (max-width: 768px) {
  .product-container {
    flex-direction: column;
  }
}
.meal {
  max-width: 1200px;
  margin: auto;
}
.instructions {
  margin: 50px 0;
  text-align: left;
}
.img-holder {
  max-width: 600px;
}
.img-holder img {
  max-width: 100%;
}
.recipe-holder {
  text-align: left;
  padding-left: 30px;
}
.btn-secondary a {
  color: white;
}
</style>
